import { gql, useMutation } from '@apollo/client';
import React, { ReactElement, useCallback, useContext, useEffect } from 'react';
import { CommunityContext } from '../../../../common_lib_front/communityConfigs/communityContextProvider';
import { backendResponse } from '../../../../common_lib_front/types/backendResponse';
import { RecipientInfo } from '../../../../components/inviteGuestForm/inviteGuestForm';
import InviteGuestFormContainer from '../../../../components/inviteGuestForm/inviteGuestFormContainer';
import { useGetHostRentals } from '../../../../hooks/useGetHostRentals';
import { IGPassInfo } from '../inviteGuestRequests';
import { InviteGuestDispatchType } from '../useInviteGuest';

export const INVITE_GUEST = gql`
  mutation Mutation($invites: [InviteGuestInput!]!) {
    inviteGuests(invites: $invites) {
      error
      success
      data {
        registration {
          registrationId
        }
        passes {
          passId
        }
      }
    }
  }
`;
export type INVITE_GUEST_VARS = {
  invites: {
    passes: Array<{
      startDate: string;
      endDate: string;
      passInfoId: string;
    }>;
    rentalInfo: {
      address: string;
      arrivalDate: string;
      departureDate: string;
    };
    guestInfo: {
      firstName: string;
      lastName: string;
      email: string;
      phoneNumber: string;
    };
    hostWillPay?: boolean;
  }[];
};
export type INVITE_GUEST_RES = {
  inviteGuests: backendResponse<
    {
      registration?: {
        registrationId?: string;
      };
      passes?: {
        passId?: string;
      }[];
    }[]
  >;
};

function useSubmitHandler(
  editData: InviteGuestDispatchType,
  data: RecipientInfo[],
): {
  loading: boolean;
  submitHandler: () => Promise<{
    data?: (backendResponse<unknown> | undefined)[];
    errors?: (string | undefined)[];
  }>;
} {
  const [doInvite, { loading }] = useMutation<INVITE_GUEST_RES, INVITE_GUEST_VARS>(
    INVITE_GUEST,
    {
      onCompleted: d => {
        if (d?.inviteGuests.success) {
          const invites = d?.inviteGuests?.data || [];
          // after all have completed
          invites.forEach((invite, recipientIdx) => {
            // add registration id to data
            if (invite?.registration?.registrationId) {
              editData({
                type: 'editRecipient',
                payload: {
                  recipientIdx: recipientIdx,
                  recipientData: {
                    registrationId: invite.registration.registrationId,
                  },
                },
              });
            }
            (invite?.passes || []).forEach((pass, passIdx) => {
              editData({
                type: 'editRental',
                payload: {
                  recipientIdx: recipientIdx,
                  rentalIdx: passIdx,
                  passData: {
                    passId: pass.passId,
                  },
                },
              });
            });
          });
          // clear any where hosts will not pay
          editData({
            type: 'clearWillNotPay',
            payload: {},
          });
        }
      },
    },
  );
  const submitHandler = useCallback(
    () =>
      doInvite({
        variables: {
          invites: data.map(recipient => ({
            guestInfo: {
              firstName: recipient.firstName,
              lastName: recipient.lastName,
              email: recipient.email,
              phoneNumber: recipient.phoneNumber,
              // address: recipient.address,
              // arrivalDate: recipient.arrivalDate,
              // departureDate: recipient.departureDate,
            },
            rentalInfo: {
              address: recipient.address,
              arrivalDate: recipient.arrivalDate,
              departureDate: recipient.departureDate,
            },
            passes: recipient.passes.map(rental => ({
              startDate: rental.startDate || recipient.arrivalDate,
              endDate: rental.endDate || recipient.departureDate,
              passInfoId: rental.passInfoId,
            })),
            hostWillPay: recipient.willPayForPass,
          })),
        },
      }).then(res => {
        // transform results to a format that is easily interpreted by components
        const errors = [];
        if (res.data?.inviteGuests.error) {
          errors.push(res.data.inviteGuests.error);
        } else if (res.errors) {
          errors.push('Something went wrong. Please try again later.');
        }
        return {
          data: [res.data?.inviteGuests || undefined],
          errors,
        };
      }),
    [data, doInvite],
  );

  return {
    submitHandler,
    loading,
  };
}

type InviteGuestPageProps = {
  data: Array<RecipientInfo>;
  editData: InviteGuestDispatchType;
  fastPassSelected: boolean;
  setFastPassSelected: any;
  passInfoData: IGPassInfo[];
  error?: string;
};

export default function InviteGuestPage(props: InviteGuestPageProps): ReactElement {
  const { data, editData, fastPassSelected, setFastPassSelected, error } = props;
  // const submitHandler = useSubmitHandler(editData, data);
  const { submitHandler, loading } = useSubmitHandler(editData, data);
  const { featuresConfig } = useContext(CommunityContext);

  // if a community requires hosts to pay, make sure all passes are marked as will pay
  useEffect(() => {
    if (!featuresConfig?.host?.inviteGuest?.hostMustPay) return;
    data.forEach((ri, idx) => {
      if (!ri.willPayForPass) {
        editData({
          type: 'editRecipient',
          payload: {
            recipientIdx: idx,
            recipientData: {
              willPayForPass: true,
            },
          },
        });
      }
    });
  }, [data, editData, featuresConfig?.host?.inviteGuest?.hostMustPay]);

  const { data: hostRentals } = useGetHostRentals({
    variables: {
      hostInfoId: null,
      approvedOnly: true,
    },
  });


  return (
    <InviteGuestFormContainer
      data={data}
      editData={editData}
      fastPassSelected={fastPassSelected}
      setFastPassSelected={setFastPassSelected}
      submitHandler={submitHandler}
      error={error}
      maxPasses={50}
      loading={loading}
      disabled={loading}
      targetPortal="guest"
      formConfig={{
        firstName: { required: true },
        lastName: { required: true },
        companyName: { hidden: true },
        email: { required: false },
        phoneNumber: { required: false },
        address: {
          required: true,
          label: "Rental Address of Guest's Stay",
        },
        willPayForPass: {
          hidden: featuresConfig?.host?.inviteGuest?.hostMustPay,
        },
        passType: {},
      }}
      validators={[
        {
          validIf: ri => ri.phoneNumber.length >= 11 || ri.email.length >= 3,
          message:
            'Please enter a valid phone number. A phone number is required to issue an invite.',
        },
        {
          validIf: ri =>
            !!hostRentals?.getRentalsByHost.data?.find(a => a.address === ri.address),
          message: 'Please select an approved address from the drop down for all passes.',
        },
      ]}
      formTextConfig={{
        recipientTag: 'Reservation',
        recipientMessage:
          'To invite a rental guest please enter a phone number or email address. Your invite will be sent by text and/or, email.',
      }}
    />
  );
}
