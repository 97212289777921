import { gql, useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import {
  newRentalUnitInfo,
  rentalUnitInfo,
} from '../../../common_lib_front/types/rentalUnitInfo';

const GET_INFO = gql`
  query GetCheckoutInfo($registrationId: String!) {
    getHostInfoData(registrationId: $registrationId) {
      success
      error
      data {
        hostInfoId
        email
        firstName
        lastName
        address
        phoneNumber
        companyName
        city
        state
        ownerPin
        zipCode
      }
    }
    getRentalByRegistration(registrationId: $registrationId) {
      success
      error
      data {
        rentalUnitId
        address
        city
        state
        zipCode
        companyName
        email
        primaryEmergencyContactName
        primaryEmergencyPhone
        secondaryEmergencyPhone
        propertyManagerName
        propertyManagerPhone
      }
    }
  }
`;

const EDIT_STEP_NUMBER = gql`
  mutation EditRegistrationStepNumber(
    $registrationId: String!
    $rentalList: RentalUnitDataInput!
  ) {
    editRegistrationStepNumber(
      stepNumber: 2
      registrationId: $registrationId
      registrationType: "rental"
    ) {
      success
      error
    }
    editOrInsertRentalUnits(rentalList: $rentalList, registrationId: $registrationId) {
      success
      error
    }
    sendHostConfirmationEmail(registrationId: $registrationId) {
      success
    }
  }
`;
const EDIT_HOST_APPLICATION = gql`
  mutation EditHostApplication($registrationId: String!) {
    editApplicationStatus(status: "pending-review", registrationId: $registrationId) {
      success
      error
    }
  }
`;
type SecureCheckout = {
  registrationId: string;
  hostData: any;
  rentalData: Array<rentalUnitInfo>;
  loading: boolean;
  error: string;
  advanceStep: () => void;
  updateApplicationStatus: () => void;
};

export default function useSecureCheckout(): SecureCheckout {
  const { registrationId } = useParams<{ registrationId: string }>();
  const {
    data: allData,
    loading: allLoading,
    error: allError,
  } = useQuery(GET_INFO, {
    fetchPolicy: 'network-only',
    variables: { registrationId },
  });

  const [doAdvanceStep] = useMutation(EDIT_STEP_NUMBER);
  const advanceStep = () => {
    doAdvanceStep({
      variables: {
        registrationId,
        rentalList: {
          data: allData?.getRentalByRegistration?.data.map((r: any) => ({
            ...r,
            complete: true,
            __typename: undefined,
            hostPropertySlug: undefined,
            guestPropertySlug: undefined,
          })),
        },
      },
    });
  };
  const [doEditHostApplication] = useMutation(EDIT_HOST_APPLICATION);
  const updateApplicationStatus = () => {
    doEditHostApplication({
      variables: {
        registrationId,
      },
    });
  };
  return {
    registrationId,
    hostData: allData?.getHostInfoData?.data || {},
    rentalData:
      allData?.getRentalByRegistration?.data?.map((r: any) => newRentalUnitInfo(r)) || [],
    loading: allLoading,
    error: allError?.message || '',
    advanceStep,
    updateApplicationStatus,
  };
}
