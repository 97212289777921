import Tippy from '@tippyjs/react';
import { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ActionImg } from '../../assets/actionIcon.svg';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import isRefundOptionAllow from '../../common_lib_front/utilities/refundOptionConfig';
import { createPaymentSession } from '../../hooks/usePaymentSession';
import useGuestTable from '../guestTable/useGuestTable';
import style from './guestDataGrid.module.css';

interface PopupCellRendererProps {
  data: {
    firstName: string;
    lastName: string;
    data?: {
      passId: string;
      passNumber: string;
    };
    passId: string;
    passNumber: string;
    registrationId: string;
    actions: {
      guestInfo?: {
        email: string;
        phoneNumber: string;
      };
      reservationExtension: {
        reservationExtensionId: string;
        newStartDate: string | null;
        newEndDate: string | null;
        fulfilled: boolean;
        registrationId: string;
      } | null;
      passes: {
        number: string;
        passId: string;
        url: string;
        paid: string;
        status: string;
      }[];
      registration: {
        registrationId: string;
      };
    };
  };
}

const PopupCellRenderer: React.FC<PopupCellRendererProps> = props => {
  const tippyRef = useRef<any>(); // Use proper type if available
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);
  const { reinvite } = useGuestTable();
  const history = useHistory();
  const { featuresConfig } = useContext(CommunityContext);

  const onClickHandler = (option: string) => {
    hide();
    if (option === 'reinvite') {
      if (
        window.confirm(
          `Are you sure you would like to re-invite ${props.data?.firstName} ${props.data?.lastName}?
        This will resend the invitation email/text`,
        )
      ) {
        if (
          props.data.actions.guestInfo?.email ||
          props.data.actions.guestInfo?.phoneNumber
        ) {
          reinvite(
            props.data.actions.guestInfo?.email,
            props.data.actions.guestInfo?.phoneNumber,
            props.data.actions.registration.registrationId || '',
          )
            .then((resp: any) => {
              if (resp?.data?.reinviteGuest?.success) {
                window.alert('Reinvite successful');
              } else {
                window.alert('ERROR: Reinvite unsuccessful');
              }
            })
            .catch(() => {
              window.alert('ERROR: Reinvite unsuccessful');
            });
        }
      }
    }
  };

  const openLinkHandler = async (option: string) => {
    hide();
    const regnId = props.data.actions.registration.registrationId;
    console.log('REGISTRATION ID: ' + regnId);
    if (option === 'details') {
      // window.location.href = '../invite-guest/details/' + regnId;
      history.push(`/invite-guest/details/${regnId}`);
    }
    if (option === 'edit') {
      // window.location.href = '../invite-guest/edit/' + regnId;
      history.push(`/invite-guest/edit/${regnId}`);
    }
    if (option === 'refund') {
      // window.location.href = '../invite-guest/refund/' + regnId;
      history.push(`/invite-guest/refund/${regnId}`);
    }
    if (option === 'delete') {
      // window.location.href = '../invite-guest/delete-guest/' + regnId;
      history.push(`/invite-guest/delete-guest/${regnId}`);
    }
    if (option === 'add-pass-to-invite') {
      history.push(`/invite-guest/add-pass-to-invite/${regnId}`);
    }
    if (option === 'pay') {
      const paymentSessionId = (
        await createPaymentSession({
          newPaymentSession: {
            registrationIds: [regnId],
          },
        })
      ).data?.createPaymentSession.data?.paymentSessionId;
      if (!paymentSessionId) {
        console.error('No payment session id');
        return;
      }
      history.push(`/invite-guest/payment?paymentSessionId=${paymentSessionId}`);
    }
    if (option === 'share') {
      props.data.passId = props.data.actions.passes[0].passId;
      const data = props.data;
      history.push('/invite-guest/share-pass', { data });
    }
    if (option === 'viewdownload') {
      const passInd = props.data.actions.passes.findIndex(
        item => item.number == props.data.passNumber,
      );
      const pId = props.data.actions.passes[passInd].passId;
      // window.location.href = '../invite-guest/view-pass/' + regnId + '/' + pId;
      history.push(`/invite-guest/view-pass/${regnId}/${pId}`);
    }
  };

  const allowReinvite: boolean =
    !featuresConfig?.host?.inviteGuest?.hostMustPay ||
    ['paid', 'ach-pending', 'no-charge'].includes(props.data.actions.passes[0]?.paid);

  const dropDownContent = (
    <div className={style.menuContainer}>
      <div onClick={() => openLinkHandler('details')} className={style.menuItem}>
        <span className={style.itemText}>Details</span>
      </div>
      {allowReinvite ? (
        <div onClick={() => onClickHandler('reinvite')} className={style.menuItem}>
          <span className={style.itemText}>Reinvite</span>
        </div>
      ) : null}
      {isRefundOptionAllow(
        props.data.actions.passes[0]?.status,
        props.data.actions.passes[0]?.paid,
      ) && (
        <div onClick={() => openLinkHandler('refund')} className={style.menuItem}>
          <span className={style.itemText}>Refund</span>
        </div>
      )}
      <div onClick={() => openLinkHandler('edit')} className={style.menuItem}>
        <span className={style.itemText}>Edit</span>
      </div>
      {/* <div onClick={() => openLinkHandler('share')} className={style.menuItem}>
        <span className={style.itemText}>Share</span>
      </div> */}
      <div onClick={() => openLinkHandler('delete')} className={style.menuItem}>
        <span className={style.itemText}>Delete</span>
      </div>
      <div
        onClick={() => openLinkHandler('add-pass-to-invite')}
        className={style.menuItem}
      >
        <span className={style.itemText}>Add Pass to This Invite</span>
      </div>
      {props.data.actions.passes[0]?.passId && props.data.actions.passes[0].url ? (
        <div onClick={() => openLinkHandler('viewdownload')} className={style.menuItem}>
          <span className={style.itemText}>View/Download</span>
        </div>
      ) : null}
      {/* eslint-disable- indent */}
      {['unpaid', 'ach-failed'].includes(props.data.actions.passes[0]?.paid) ||
      props.data.actions?.reservationExtension?.fulfilled === false ? (
          <div onClick={() => openLinkHandler('pay')} className={style.menuItem}>
            <span className={style.itemText}>Pay</span>
          </div>
        ) : null}
      {/* eslint-enable- indent */}
    </div>
  );

  return (
    <Tippy
      ref={tippyRef}
      content={dropDownContent}
      visible={visible}
      onClickOutside={hide}
      allowHTML={true}
      arrow={false}
      appendTo={document.body}
      interactive={true}
      placement="right"
    >
      <div className={style.actionBox}>
        <button className={style.actionBtn} onClick={visible ? hide : show}>
          <p>Actions</p>
          <ActionImg />
        </button>
      </div>
    </Tippy>
  );
};

export default PopupCellRenderer;
