export type rentalUnitInfo = {
  rentalUnitId: string;
  registrationId: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  companyName: string;
  primaryEmergencyContactName: string;
  primaryEmergencyPhone: string;
  secondaryEmergencyPhone: string | null;
  email: string | null;
  passStatus: string | null;
  propertyManagerName: string | null;
  propertyManagerPhone: string | null;
  hostPay: boolean;
  guestPay: boolean;
  occupancy: string | null;
  enableContactDetails: boolean;
  price: string | null;
  hostPropertySlug: string | null;
  guestPropertySlug: string | null;
  [key: string]: string | any | null;
};

export function newRentalUnitInfo(data?: any) {
  return {
    rentalUnitId: data?.rentalUnitId || '',
    registrationId: data?.registrationId || '',
    address: data?.address || '',
    city: data?.city || '',
    state: data?.state || '',
    zipCode: data?.zipCode || '',
    companyName: data?.companyName || '',
    primaryEmergencyContactName: data?.primaryEmergencyContactName || '',
    primaryEmergencyPhone: data?.primaryEmergencyPhone || '',
    secondaryEmergencyPhone: data?.secondaryEmergencyPhone || '',
    email: data?.email || '',
    price: data?.price || null,
    passStatus: data?.passStatus || null,
    occupancy: data?.occupancy || 0,
    propertyManagerName: data?.propertyManagerName || '',
    propertyManagerPhone: data?.propertyManagerPhone || '',
    startDate: data?.startDate || '',
    endDate: data?.endDate || '',
    hostPay: data?.hostPay || false,
    guestPay: data?.guestPay || false,
    enableContactDetails: data?.enableContactDetails || false,
    hostPropertySlug: data?.hostPropertySlug || null,
    guestPropertySlug: data?.guestPropertySlug || null,
  };
}
