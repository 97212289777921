import Tippy from '@tippyjs/react';
import React, { useState, useRef } from 'react';
import { ReactComponent as ActionImg } from '../../../assets/actionIcon.svg';
import { exportPdf } from '../../../common_lib_front/utilities/pdf';
import isRefundOptionAllow from '../../../common_lib_front/utilities/refundOptionConfig';
import style from './popUpCellRender.module.css';

interface PopupCellRendererProps {
  data: {
    passId: string;
    status: string;
    passType: string;
    url: string;
    paid: string;
  };
}

/* eslint-disable react/prop-types */
const PopupCellRenderer: React.FC<PopupCellRendererProps> = ({ data }) => {
  const tippyRef = useRef<Element>(null);
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const downloadPdf = async () => {
    const pdf: any = await exportPdf([data?.url]);
    if (pdf) {
      pdf.save('access-passes.pdf');
    }
  };

  const printPdf = async () => {
    const pdf: any = await exportPdf([data?.url]);
    if (pdf) {
      const blob = pdf.output('blob');
      const nextWindow = window.open(URL.createObjectURL(blob), '_blank');
      nextWindow?.focus();
      nextWindow?.print();
    }
  };

  const openLinkHandler = (option: string) => {
    hide();
    if (option === 'renew') {
    }
    if (option === 'refund') {
      window.location.href = `../resident/refund-pass/${data.passId}`;
    }
    if (option === 'download') {
      downloadPdf();
    }
    if (option === 'print') {
      printPdf();
    }
    if (option === 'share') {
      window.location.href = '../resident/share-pass';
    }
    if (option === 'delete') {
      window.location.href = `../resident/share-pass/${data.passId}`;
    }
  };

  const dropDownContent = (
    <div className={style.menuContainer} role="menu">
      <div
        onClick={() => openLinkHandler('renew')}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            openLinkHandler('renew');
          }
        }}
        role="menuitem"
        tabIndex={0} // Add tabIndex to make it focusable
        className={style.menuItem}
      >
        <span className={style.itemText}>Renew</span>
      </div>
      <div
        onClick={() => openLinkHandler('replacement')}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            openLinkHandler('replacement');
          }
        }}
        role="menuitem"
        tabIndex={0} // Add tabIndex to make it focusable
        className={style.menuItem}
      >
        <span className={style.itemText}>Replacement</span>
      </div>
      {
        isRefundOptionAllow(data.status, data.paid) && (
          <div
            onClick={() => openLinkHandler('refund')}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                openLinkHandler('refund');
              }
            }}
            role="menuitem"
            tabIndex={0} // Add tabIndex to make it focusable
            className={style.menuItem}
          >
            <span className={style.itemText}>Refund</span>
          </div>
        )}
      <div
        onClick={() => openLinkHandler('download')}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            openLinkHandler('download');
          }
        }}
        role="menuitem"
        tabIndex={0} // Add tabIndex to make it focusable
        className={style.menuItem}
      >
        <span className={style.itemText}>Download</span>
      </div>
      <div
        onClick={() => openLinkHandler('print')}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            openLinkHandler('print');
          }
        }}
        role="menuitem"
        tabIndex={0} // Add tabIndex to make it focusable
        className={style.menuItem}
      >
        <span className={style.itemText}>Print</span>
      </div>
     
      <div
        onClick={() => openLinkHandler('delete')}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            openLinkHandler('delete');
          }
        }}
        role="menuitem"
        tabIndex={0} // Add tabIndex to make it focusable
        className={style.menuItem}
      >
        <span className={style.itemText}>Delete</span>
      </div>
    </div>
  );

  return (
    <Tippy
      ref={tippyRef}
      content={dropDownContent}
      visible={visible}
      onClickOutside={hide}
      allowHTML
      arrow={false}
      appendTo={document.body}
      interactive
      placement="right"
    >
      <div className={style.actionBox}>
        <button className={style.actionBtn} onClick={visible ? hide : show}>
          <p>Actions</p>
          <ActionImg />
        </button>
      </div>
    </Tippy>
  );
};

export default PopupCellRenderer;
